
import magicStore from '@src/store/index';

import { defineComponent, inject } from 'vue';
// import Cookies from 'js-cookie';


import type { AsideState } from '@src/typings';

export default defineComponent({
  name: 'm-header',

  emits: ['asideTrigger'],

  setup() {
    return {
      aside: inject('aside') as AsideState,
      userName: magicStore.get<string>('userName'),
      // Cookies.get('userName'),
    };
  },
});
