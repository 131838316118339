/*
 * Tencent is pleased to support the open source community by making MagicEditor available.
 *
 * Copyright (C) 2023 THL A29 Limited, a Tencent company.  All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { reactive } from "vue";

import type { MagicStore, MagicStoreType } from "@src/typings";

const state = reactive<MagicStore>({
  actInfo: {},
  pages: [],
  uiConfigs: {},
  editorDefaultSelected: "",
  userName: "",
});

export default {
  set<T = MagicStoreType>(name: keyof MagicStore, value: T) {
    (state as any)[name] = value;
    console.log("admin store set ", name, " ", value);
  },

  get<T = MagicStoreType>(name: keyof typeof state): T {
    return (state as any)[name];
  },
};
