import * as qiniu from "qiniu-js";
import axios from "axios";

export function uploadfile(
  obj: any,
  uploadId: String,
  progress: Function,
  error: Function,
  success: Function
) {
  if (obj.files.length == 0) {
    error(uploadId, "未选中文件");
    return;
  }

  const file = obj.files[0];
  const fileName = file.name;
  const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
  const key = "magic-" + new Date().getTime() + "." + fileExtension;
  axios
    .get(
      "https://h5.qmxfs.com/api/getqiniuuploadtoken?mediaType=1&fileName=" + key
    )
    .then((res) => {
      const config = {
        useCdnDomain: true,
        region: qiniu.region.z0,
        checkByMD5: true,
        retryCount: 5,
        disableStatisticsReport: false,
      };
      const putExtra = {
        fname: file.name,
        params: {},
      };
      const observable = qiniu.upload(
        file,
        key,
        res.data.data.token,
        putExtra,
        config
      );
      var observer = {
        next(result: any) {
          if (progress != null) {
            progress(uploadId, result.total.percent);
          }
        },
        complete(res: any) {
          // 上传完成
          if (success != null) {
            success(uploadId, res.imageUrl);
          }
        },
      };
      observable.subscribe(observer); // 上传开始
    });
}
