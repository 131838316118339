
import { ComponentInternalInstance, computed, defineComponent, getCurrentInstance, inject, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import type { AsideState } from '@src/typings';

export default defineComponent({
  name: 'm-aside',
  setup() {
    const height = ref('auto');
    const aside = inject('aside') as AsideState;

    onMounted(() => {
      // 高度自适应
      const { proxy } = getCurrentInstance() as ComponentInternalInstance;
      const logoHeight = (proxy?.$refs.logo as Element)?.clientHeight || 40;
      height.value = `${window.document.body.clientHeight - logoHeight}px`;
      window.addEventListener('resize', () => {
        height.value = `${window.document.body.clientHeight - logoHeight}px`;
      });
    });

    return {
      aside,
      height,
      // 当前路由对应菜单项高亮
      defaultActive: computed(() => {
        const route = useRoute();
        if (route.path.startsWith('/act/my')) {
          return '/act/my';
        }
        if (route.path.startsWith('/act/all')) {
          return '/act/all';
        }
        return route.path;
      }),
    };
  },
});
