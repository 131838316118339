
import { computed, defineComponent, provide, reactive, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
// import Cookies from 'js-cookie';

import { AsideFormConfig } from '@src/config/aside-config';
import type { AsideState } from '@src/typings';
import magicStore from '@src/store/index';

export default defineComponent({
  name: 'app',
  setup() {
    const aside = reactive<AsideState>(AsideFormConfig);
    // 是否隐藏边框
    const hideFrame = computed(() => {
      const urlHideFrame = new URL(location.href).searchParams.get('hideFrame');
      return urlHideFrame || useRoute().meta?.hideFrame;
    });

    // 折叠侧边栏
    const asideTrigger: () => void = () => {
      aside.collapse = !aside.collapse;
    };

    provide('aside', aside);

    watchEffect(() => {
      // = process.env.VUE_APP_USER_NAME || 'defaultName';
      let userName: string = ''
      const arrstr = window.location.search.replace("?", "").split("&");
      for (let i = 0; i < arrstr.length; i++) {
        const temp = arrstr[i].split("=");
        if (temp[0] == "username") {
          userName = unescape(temp[1])
        }
      }
      magicStore.set("userName", userName);
    });

    return {
      hideFrame,
      hideNav: !!new URL(location.href).searchParams.get('hideNav'),
      asideTrigger,
    };
  },
});
