import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "m-aside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_aside = _resolveComponent("el-aside")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_scrollbar, {
      style: _normalizeStyle(`height: ${_ctx.height}`)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_aside, {
          class: _normalizeClass(["app-aside", { collapse: _ctx.aside.collapse }]),
          style: {"overflow-y":"auto","height":"100%"},
          width: _ctx.aside.collapse ? '64px' : '200px'
        }, {
          default: _withCtx(() => [
            (_ctx.aside.data.length)
              ? (_openBlock(), _createBlock(_component_el_menu, {
                  key: 0,
                  "background-color": "#f8fbff",
                  "text-color": "#353140",
                  "active-text-color": "#fff",
                  "unique-opened": "",
                  router: true,
                  collapse: _ctx.aside.collapse,
                  "default-active": _ctx.defaultActive
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.aside.data, (menu) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (!menu.items)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              index: menu.url,
                              key: menu.url
                            }, {
                              title: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(menu.text), 1)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("i", {
                                  class: _normalizeClass(menu.icon)
                                }, null, 2)
                              ]),
                              _: 2
                            }, 1032, ["index"]))
                          : (_openBlock(), _createBlock(_component_el_sub_menu, {
                              index: menu.url,
                              key: menu.url
                            }, {
                              title: _withCtx(() => [
                                _createElementVNode("i", {
                                  class: _normalizeClass(menu.icon)
                                }, null, 2),
                                _createElementVNode("span", null, _toDisplayString(menu.text), 1)
                              ]),
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menu.items, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_menu_item, {
                                    index: menu.url + item.url,
                                    key: menu.url + item.url
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.text), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["index"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["index"]))
                      ], 64))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["collapse", "default-active"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "width"])
      ]),
      _: 1
    }, 8, ["style"])
  ]))
}