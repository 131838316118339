import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_m_aside = _resolveComponent("m-aside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (!_ctx.hideFrame)
    ? (_openBlock(), _createBlock(_component_el_container, {
        key: 0,
        style: {"height":"100%","display":"flex"},
        class: _normalizeClass(["app", { 'hide-nav': _ctx.hideNav }])
      }, {
        default: _withCtx(() => [
          (!_ctx.$route.meta.hideAside)
            ? (_openBlock(), _createBlock(_component_m_aside, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_main, {
                style: {"background":"#ffffff","padding":"0"},
                class: "main-container"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_view, {
                    style: _normalizeStyle(_ctx.$route.meta.hideAside ? '' : 'padding: 20px')
                  }, null, 8, ["style"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]))
    : _createCommentVNode("", true)
}